import { $authHost } from './api'
import { AxiosResponse } from 'axios'

interface IResponse {
  success: boolean
}


export interface IGetInfoSbp extends IResponse {
  id: number
  phone: string
  bank_id: number
  verification_pam: boolean
  pam: string
  bank_qr_sbp: {
    id: number
    name: string
    schema_prefix: string
    external_bank_id: string
  }
}

interface IGetInfoSbpResponse extends IResponse {
  result: IGetInfoSbp | []
}

export interface IGetInfoNewSbpResponse extends IResponse {
  result: IGetInfoSbp | {}
}

export interface ISaveInfoSbpResponse extends IResponse {
  pam: ''
}

export interface IGetQrCodeResponse extends IResponse {
  result: {
    url: string
    qr: string
  }
}

class WaiterService {
  // QR-код официанта
  async getQrCode(): Promise<IGetQrCodeResponse> {
    const { data }: AxiosResponse<IGetQrCodeResponse> = await $authHost.get('/waiter/tips/qr')
    return data
  }

  // Получение информации для получения чая
  async getInfoSbp(): Promise<IGetInfoNewSbpResponse> {
    const { data }: AxiosResponse<IGetInfoNewSbpResponse> = await $authHost.get('/profile/waiter/info/sbp')
    if (!data.success) {
      const copyData = JSON.parse(JSON.stringify(data))
      copyData.result = {}
      return copyData
    }
    return data
  }

  // Сохранение информации по получению чая по СБП
  async saveInfoSbp(phone: string, bankId?: number): Promise<ISaveInfoSbpResponse> {
    const { data }: AxiosResponse<ISaveInfoSbpResponse> = await $authHost.put('/profile/waiter/info/sbp/save', {
      phone,
      bank_id: bankId
    })
    return data
  }

  async verifySbp(status: boolean) {
    const { data } = await $authHost.put('/profile/waiter/pam/verify', {verify: status})
    console.log('data', data)

  }
}

export default new WaiterService()
// "phone": "string",
//   "bank_id": 0
